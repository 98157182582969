<template lang="html">
    <section class="automatically-box">
        <div class="container grid-2 demo ">

            <div class="content demo2 grid">
                <img src="/img/confirmacion.svg" alt="icono confirmación">


            </div>


            <div class="content demo grid">
                <h1 class="title-big -whiteC demo">{{ $t('demo.thanks.intro') }}</h1>
                <br><br>
                <p class=" -whiteC text">
                    {{ $t('demo.thanks.text1') }}
                </p>
                <br>
                <p class=" -whiteC text">
                    {{ $t('demo.thanks.text1_1') }}
                </p>
                <br>
                <p class=" -whiteC text">
                    {{ $t('demo.thanks.text1_2') }}
                </p>
                <br>
                <p class="-whiteC text">
                    {{ $t('demo.thanks.text2') }} <a href="mailto:support@r-sami.com" class="enlace">support@r-sami.com</a><br>{{$t('demo.thanks.text4')}}
                </p>
                <br>


            </div>
        </div>
    </section>

</template>

<script lang="js">


</script>
  
  
  <style scoped lang="scss">
 
  *{
    color:#1E2F3F !important;
  }
  .container {
      margin-top: 50px
  }
  
  .title-big {
      font-weight: 700;
      font-size: 35px;
      line-height: 44px;
      letter-spacing: 0.2px;
  }
  
  .text {
      font-weight: 700;
      font-size: 24px;
      line-height: 183.85%;
      font-style: normal
  }
  .enlace {
      color: #0AA6DF !important;

  }
  @media screen and (max-width:900px) {
      img {
          width: 300px;
      }
  
      .content.demo2.grid {
          display: flex;
          align-items: center;
          justify-content: center;
      }
  
      .grid-2 {
          display: flex;
          flex-wrap: wrap;
          padding: 40px 0;
          justify-content: center;
          flex-direction: column-reverse;
          align-content: center;
          align-items: center;
      }
  
      .container .content.demo {
          width: 80%;
          padding-left: 0px;
      }
  
      .title-big {
          font-weight: 700;
          font-size: 25px;
          line-height: 44px;
          letter-spacing: 0.2px;
      }
  
      .text {
          font-weight: 700;
          font-size: 18px;
          line-height: 183.85%;
          font-style: normal;
      }
  }
  </style>